<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12 md:col-12">
      <app-assessment-field
        [questionnaireCode]="questionnaireCode"
        [questionnaireValueCode]="data?.questionnaireValueId"
        [label]="'modules.framework.attestation.fields.questionnaireValueId.label' | translate: 'Questionnaire Value'"
        [placeholder]="
          'modules.framework.attestation.fields.questionnaireValueId.placeholder'
            | translate: 'Enter Questionnaire Value'
        "
        [control]="answersControl"
      >
      </app-assessment-field>
    </div>

    <div class="col-12 md:col-6">
      <app-drop-down-input
        [label]="'modules.framework.attestation.fields.controlComplianceStatus.label' | translate: 'Compliance Status'"
        [placeholder]="
          'modules.framework.attestation.fields.controlComplianceStatus.placeholder'
            | translate: 'Enter Compliance Status'
        "
        [name]="'type'"
        [optionLabel]="'label'"
        [items]="listOfTypes"
        [optionValue]="'value'"
        [control]="formGroup?.controls?.controlComplianceStatus"
        [viewMode]="fieldViewMode"
        [badgeView]="true"
      ></app-drop-down-input>
    </div>
  </div>

  <div class="flex flex-row-reverse gap-2">
    @if (viewModeService.viewMode != 'view') {
      <app-button [action]="submitSaveButtonAction"></app-button>
    }
  </div>
</form>
