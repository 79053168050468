import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AttestationDto, BaseForm, EntityTypeFieldDto, getEnumOptions } from '@shared/classes';
import { ViewModeService } from '@shared/services/view-mode.service';

@Component({
  selector: 'app-attestation-item-form',
  templateUrl: './attestation-item-form.component.html',
  styleUrls: ['./attestation-item-form.component.scss'],
})
export class AttestationItemFormComponent extends BaseForm<AttestationDto> implements OnInit {
  respList = [EntityTypeFieldDto.CategoryEnum.Responsibility];

  answersControl: FormControl = new FormControl();

  categoryList = [EntityTypeFieldDto.CategoryEnum.Responsibility];

  @Output() sendSubmit: EventEmitter<any> = new EventEmitter();

  questionnaireCode: string;

  listOfTypes = getEnumOptions(AttestationDto.ControlComplianceStatusEnum);

  constructor(
    public viewModeService: ViewModeService
    // public controlDataService: ControlDataService,
  ) {
    super(viewModeService, 'ATTESTATION');
  }

  ngOnInit(): void {}

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;

    // if(this.data) {
    //     this.controlDataService.getByIdOrCode(this.data?.controlId).subscribe((control) => {
    //         this.questionnaireCode = control?.data?.controlAttestationTemplate;
    //     })
    // }
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      controlComplianceStatus: new FormControl(null),
    });
  }

  onSendForm() {
    this.sendSubmit.emit();
  }
}
